import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Suspense } from "react";
import ProtectedRoutes from "./Middleware/ProtectedRoutes";
import Loading from "./Components/Loading/Loading";
import { ToastContainer } from "react-toastify";

const Login = lazy(() => import("./Pages/Login"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const ProductionProblem = lazy(() => import("./Pages/ProductionProblem"));
const History = lazy(() => import("./Pages/History"));
const ViewChat = lazy(() => import("./Pages/ViewChat"));
const AddRequirement = lazy(() => import("./Pages/AddRequirement"));
const BundleAssignment = lazy(() => import("./Pages/BundleAssignment"));
const AssignBundle = lazy(() => import("./Pages/AssignBundle"));
const OperatorGroup = lazy(() => import("./Pages/OperatorGroup"));
const BatchDetails = lazy(() => import("./Pages/BatchDetails"));
const TotalBundleAffected = lazy(() => import("./Pages/TotalBundleAffected"));
const ViewProductionProblem = lazy(() =>
  import("./Pages/ViewProductionProblem")
);
const AddProductionProblem = lazy(() => import("./Pages/AddProductionProblem"));
const ResolveProductionProblem = lazy(() =>
  import("./Pages/ResolveProductionProblem")
);
const ResolveProdProblemSingle = lazy(() =>
  import("./Pages/ResolveProdProbSingle")
);
const CuttingSummary = lazy(() =>
  import("./Pages/ManufactureDataCutting/CuttingSummary")
);
const Journey = lazy(() => import("./Pages/ManufactureDataCutting/Journey"));
const ManufacturingPacking = lazy(() => import("./Pages/ManufacturingPackage"));

const App = () => {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  return (
    <Router>
      <ToastContainer draggable position="top-right" autoClose={3000} />
      <Suspense
        fallback={
          <Loading color={"rgba(1, 18, 34, 1)"} size={42} height={true} />
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            {/* Gaurav Bassi Sir Modules */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/add-requirements" element={<AddRequirement />} />
            <Route path="/production-problem" element={<ProductionProblem />} />
            <Route path="/history" element={<History />} />
            <Route path="/bundle-assignment" element={<BundleAssignment />} />
            <Route path="/assign-bundle" element={<AssignBundle />} />
            <Route path="/operator-group" element={<OperatorGroup />} />
            <Route path="/batch-details" element={<BatchDetails />} />
            <Route
              path="/total-bundle-affected"
              element={<TotalBundleAffected />}
            />
            <Route
              path="/view-production-problem"
              element={<ViewProductionProblem />}
            />
            <Route
              path="/add-production-problem"
              element={<AddProductionProblem />}
            />
            <Route
              path="/resolve-production-problem"
              element={<ResolveProductionProblem />}
            />
            <Route
              path="/resolve-prod-problem"
              element={<ResolveProdProblemSingle />}
            />
            <Route path="/manufacture-cutting" element={<CuttingSummary />} />
            <Route path="/journey/:id/:status" element={<Journey />} />
            <Route path="/view-chat" element={<ViewChat />} />

            {/* Hardeep Sir Modules */}
            <Route
              path="/manufacturing-packing"
              element={<ManufacturingPacking />}
            />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
